import axios from 'axios'
import * as types from './mutation-types'
import { useStore } from '../store/index'
import { reactive } from 'vue'
// import { useI18n } from 'vue-i18n'

const state = () => {
  return {
    // AccessToken, should be only in memory
    token: '',
    shopID: localStorage.getItem('shopID') || '',
    shop: reactive({
      id: null,
      url: null,
      name: null,
      type: null,
      isActive: false,
      initialized: null
    }),
    me: {
      id: localStorage.getItem('clientID') || null
    },
    errors: {
      login: null,
      register: null,
      requesting: false
    }
  }
}

// getters
const getters = {
  isLoggedIn(state) {
    return !!state.token
  },
  authToken(state) {
    return state.token
  },
  loginErrorMsg(state) {
    return state.errors.login
  },
  loginInProgress(state) {
    return state.errors.requesting
  },
  getClientId(state) {
    return state.me.id
  },
  shop(state) {
    return state.shop
  },
  shopID(state) {
    return state.shopID
  },
  isShopConfigured(state) {
    if (!state.shop || !state.shop.initialized) {
      return null
    }

    console.error(state.shop.initialized)
    return state.shop && state.shop.initialized !== 'uninitialized'
  }
}

// actions
const actions = {
  async openDashboard({ commit }, state) {
    axios
      .post(window.nappmaster + '/client/dash', null, { withCredentials: true })
      .then(function(response) {
        if (state.first) {
          location.href = `${state.href}/dashboard/login/${response.data.code}?firstTime=1`
        } else {
          location.href = `${state.href}/dashboard/login/${response.data.code}`
        }
      })
      .catch(function(_error) {
      })
  },
  async getAccessToken({ commit }) {
    const refreshExp = localStorage.getItem('tokenRefreshExp')
    if (!refreshExp || refreshExp < Date.now()) {
      return
    }

    axios
      .get(window.nappmaster + '/auth', {
        withCredentials: true
      })
      .then(response => {
        if (response.status === 200) {
          const now = Date.now()
          const date = Date.parse(response.data.accessExpiresAt)

          const timeLeft = date - now
          setTimeout(() => {
            useStore().dispatch('auth/getAccessToken')
          }, timeLeft - 900000) // Less 15 Minutes

          commit(types.SET_AUTH_TOKEN, response.data)
        } else {
          localStorage.clear()
        }
      })
      .catch()
  },
  async login({ commit }, state) {
    commit(types.AUTH_REQUESTING, true)
    axios
      .post(window.nappmaster + '/auth', state, { withCredentials: true })
      .then(function(response) {
        commit(types.AUTH_REQUESTING, false)
        commit(types.AUTH_LOGIN_OK, response.data)
      })
      .catch(function(_error) {
        commit(types.AUTH_REQUESTING, false)
        commit(
          types.AUTH_LOGIN_FAILED,
          'Informação incorreta. Por favor tente novamente.'
        )
      })
  },
  logout({ commit }) {
    commit(types.AUTH_LOGOUT)
  },
  async getShop({ commit }, state) {
    // axios.defaults.withCredentials = true;
    axios
      .get(window.nappmaster + '/shop/', { withCredentials: true })
      .then(function(response) {
        commit(types.SET_SHOP, response.data)
      })
      .catch(function(_error) {
        commit(types.AUTH_LOGOUT)
      })
  },
  async updateShopType({ commit, state }, type) {
    if (!state.shop) {
      return
    }

    axios
      .put(`${window.nappmaster}/shop/${state.shop.id}/type/${type}`, { withCredentials: true })
      .then(function(response) {
      })
      .catch(function(_error) {
      })
  }
}

// mutations
const mutations = {
  [types.SET_AUTH_TOKEN](state, value) {
    localStorage.setItem('tokenRefreshExp', value.refreshExpiresAt)
    localStorage.setItem('tokenAuthExp', value.accessExpiresAt)

    axios.defaults.headers.common.Authorization = value.accessToken
    state.token = value.accessToken
  },
  [types.SET_SHOP](state, value) {
    state.shop = {
      id: value.id,
      url: value.url,
      name: value.name,
      type: value.type,
      isActive: value.isActive,
      initialized: value.initialized
    }
  },
  [types.AUTH_LOGOUT](state) {
    localStorage.removeItem('tokenRefreshExp')
    localStorage.removeItem('tokenAuthExp')
    localStorage.removeItem('shopID')
    localStorage.removeItem('clientID')

    axios.defaults.headers.common.Authorization = ''

    state.shop = {
      id: null,
      url: null,
      name: null,
      type: null,
      isActive: false,
      initialized: null
    }

    state.me.id = null
    state.token = ''
    state.shopID = ''
  },
  [types.AUTH_LOGIN_OK](state, value) {
    localStorage.setItem('tokenRefreshExp', value.refreshExpiresAt)
    localStorage.setItem('tokenAuthExp', value.accessExpiresAt)
    localStorage.setItem('shopID', value.shopID)
    localStorage.setItem('clientID', value.clientID)

    axios.defaults.headers.common.Authorization = value.accessToken

    state.me.id = value.clientID
    state.token = value.accessToken
    state.shopID = value.shopID
  },
  [types.AUTH_LOGIN_FAILED](state, value) {
    state.errors.login = value
  },
  [types.AUTH_REQUESTING](state, value) {
    state.errors.requesting = value
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
